import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";
import { ShowPopup } from "context/popup";
import LogoClose from "assets/images/menu/icon-close.svg";

export default (props) => {
  const [expandedCards, setExpandedCards] = useState([]);

  const handleToggle = (index) => {
    setExpandedCards((prevExpandedCards) =>
      prevExpandedCards.includes(index)
        ? prevExpandedCards.filter((i) => i !== index)
        : [...prevExpandedCards, index]
    );
  };

  return {
    component: "container",
    style: {
      width: "100%",
      minHeight: "50%",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      borderRadius: 8,
      boxShadow: "1px 1px 8px #bfbfbf",
      transition: "min-height 0.5s ease, transform 0.5s ease",
      paddingTop: 8,
      gap: 8,
    },
    children: [
      //cardMain
      {
        component: "container",
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 6,
          paddingBottom: 18,
          gap: 4,
          transition: "min-height 0.5s ease, transform 0.5s ease",
        },
        children: props?.item?.map((menu, index) => {
          const isExpanded = expandedCards.includes(index);
          return {
            component: "container",
            style: {
              width: "100%",
              minHeight: "8vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              cursor: "pointer",
              borderBottom: "1px solid gray",
              transition: "min-height 0.5s ease, transform 0.5s ease",
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  flexDirection: "column",
                  transition: "min-height 0.5s ease, transform 0.5s ease",
                },
                children: [
                  Icon({
                    size: 32,
                    title: menu.title,
                    src: LogoClose,
                    style: {
                      iconBox: {
                        paddingLeft: 12,
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: 8,
                        minHeight: 42,
                        maxHeight: 42,
                      },
                      title: {
                        alignItems: "center",
                        width: "100%",
                        textAlign: "flex-start",
                        fontSize: 18,
                      },
                    },
                    onPress: () => {
                      handleToggle(index);
                    },
                  }),
                ],
              },

              //subContent
              {
                component: "container",
                style: {
                  display:
                    menu.children?.length > 0 && isExpanded ? "flex" : "none",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "10%",
                  padding: 12,
                  transition: "min-height 0.5s ease, transform 0.5s ease",
                },
                children: menu.children?.map((subMenu) => {
                  return {
                    render: (
                      <Fade top>
                        {Render({
                          component: "text",
                          text: subMenu?.description,
                          style: {
                            display: "flex",
                            width: "82%",
                            fontSize: 14,
                            alignItems: "flex-start",
                            textAlign: "flex-start",
                            justifyContent: "flex-start",
                            fontFamily: "Poppins",
                          },
                        })}
                      </Fade>
                    ),
                  };
                }),
              },
            ],
          };
        }),
      },
    ],
  };
};
